<template>
  <register-container>
    <template slot="header">
      <div class="d-flex landing-header align-center px-3 px-sm-10">
        <div class="sentry-logo"></div>
        <div class="covid-landing-header">
          {{ pageTitle || $t('registration.registerCovid.title') }}
        </div>
      </div>
    </template>
    <template slot="subtitle">{{ pageSubtitle }} </template>
    <div class="d-flex justify-center landing-content-wrapper px-3 px-sm-16">
      <div class="landing-content flex-grow-1">
        <div>
          {{ $t('registration.registerCovid.message.part1') }}
        </div>
        <div class="mt-4">
          {{ $t('registration.registerCovid.message.part2') }}
          <b> {{ $t('registration.registerCovid.message.part3') }}</b>
        </div>
        <div class="mt-4">
          {{ $t('registration.registerCovid.message.part4') }}
        </div>
      </div>
    </div>

    <div class="disclaimer landing-content-wrapper px-3 px-sm-16 mt-10">
      <v-checkbox
        :label="$t('registration.registerCovid.consent')"
        v-model="disclaimer"
      ></v-checkbox>
    </div>

    <div class="get-started d-flex justify-center mt-10">
      <hover-button active="true" :disabled="!isComplete" @click="getStarted">{{
        $t('registration.registerCovid.getStarted')
      }}</hover-button>
    </div>
  </register-container>
</template>

<style scoped>
.landing-content {
  font-size: 1.6em;
}
.landing-header {
  height: 125px;
  position: relative;
}
.sentry-logo {
  background-image: url(../images/sentry-logo-square.png);
  background-size: 125%;
  background-position: center;
  min-width: 100px;
  height: 100%;
}

@media all and (max-width: 600px) {
  .covid-landing-header {
    font-size: 0.8em;
  }
  .landing-content {
    font-size: 1.1em;
  }
  .sentry-logo {
    min-width: 20%;
  }
}
</style>
<script>
import to from 'await-to-js';
import HoverButton from '../components/HoverButton.vue';
import RegisterContainer from '../components/RegisterContainer.vue';
import GET_PROVIDER_ID from '../graphql/Query/GetProviderId.gql';

export default {
  name: 'RegisterCovidLanding',
  components: { HoverButton, RegisterContainer },
  data() {
    return {
      disclaimer: false,
    };
  },
  created() {
    if (localStorage.pharmacy) {
      this.$store.dispatch('setPreferredPharmacy', { pharmacyId: localStorage.pharmacy });
    }
    if (this.tenantUrl) {
      this.$store.commit('setRegistration', { tenantUrl: this.tenantUrl });
      this.getProviderId();
    }
  },
  computed: {
    isComplete() {
      return this.$store.state.registration.providerId && this.tenantUrl && this.disclaimer;
    },
    tenantUrl() {
      return localStorage.tenantUrl || this.$store.state.registration.tenantUrl;
    },
  },
  methods: {
    async getProviderId() {
      const [errors, query] = await to(
        this.$apollo.query({
          query: GET_PROVIDER_ID,
          variables: {
            tenantUrl: this.tenantUrl || null,
          },
        })
      );
      const { providerId } = query.data;
      if (errors) {
        this.$store.commit('setNotification', {
          color: 'error',
          text: errors || 'No provider available',
          timeout: 3000,
        });
      } else if (providerId) {
        this.$store.commit('setRegistration', {
          providerId,
        });
      }
    },
    getStarted() {
      this.$store.commit('addOtherPatientNote', {
        newNoteText: `User has consented to physician following their vaccination`,
        noteTags: ['VaccinationConsent'],
      });
      /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
  },
};
</script>
